<template>
  <v-app-bar scroll-behavior="elevate">
    <v-container>
      <v-row>
        <v-col style="width: auto; flex: none;">
          <router-link :to="{ name: 'root' }" class="brand">
            <img src="https://mezetude.s3.amazonaws.com/assets/logo.svg" width="40" height="40" style="display: inline-block; vertical-align: middle">
            <span class="h5 mb-0 ml-3">mezetude</span>
          </router-link>
        </v-col>

        <v-col class="text-right">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style scoped>
.brand {
  text-decoration: none !important;
  color: inherit;
}
</style>
